import React from 'react'
import img from '../../images/rollmasazer-2.webp'
import {Helmet} from 'react-helmet'

import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const Rollmasazer = () => {
  return (
    <Layout>
      <Seo
        title='Rollmasażer z IR | Modelowanie Sylwetki'
        description='ROLLmasażer z IR to nowoczesne urządzenie służące do modelowania
        sylwetki, redukcji cellulitu i tkanki tłuszczowej oraz do ujędrnienia
        skóry.'
        url='https://modelowaniesylwetki-wawer.pl/strefa-fitness/rollmasazer-z-ir'
      />

      <div className='page-container'>
        <div className='page'>
          <div className='content-1'>
            <h1>Rollmasażer z IR</h1>
            <div className='section'>
              <div className='image-container'>
                <img
                  src={img}
                  width='660px'
                  height='440px'
                  alt='rollmasażer z IR zdjęcie'
                ></img>
              </div>
              <div className='text'>
                <p>
                  ROLLmasażer z IR to nowoczesne urządzenie służące do modelowania
                  sylwetki, redukcji cellulitu i tkanki tłuszczowej oraz do ujędrnienia
                  skóry. Urządzenie to wspomaga odchudzenia, daje widoczne efekty a sam
                  zabieg jest bardzo relaksujący. Jest to mechaniczny masaż za pomocą
                  profilowanych drewnianych rolek, które obracając się na odpowiednio
                  dobranych obrotach, masują poszczególne partie ciała. Ważne, aby podczas
                  treningu wykonywać ćwiczenia zgodnie z ustalonym planem, koniecznie w
                  kierunku zgodnym z kierunkiem przepływu limfy. Zastosowane w urządzeniu
                  światło czerwone dodatkowo podgrzewa naszą skórę, przyspieszając
                  spalanie tkanki tłuszczowej, zwiększa przepływ krwi dając lepsze
                  dotlenienie i ukrwienie tkanek. Podczerwień oczyszcza skórę i tkanki
                  podskórne ze zbędnych toksyn czego efektem jest jędrna, zdrowa i
                  sprężysta skóra. Efekty widoczne są już po pierwszych kilku treningach.
                </p>
                <div className='cennik'>
                  <span>CENNIK</span>
                  <h2>ROLLMASAŻER</h2>
                  <ul>
                    <li>
                      <p>ROLLMASAŻER - POJEDYNCZY ZABIEG</p>
                      <span className='prices'>30 MIN | 35 zł</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Fade left>
            <Link to='/kontakt'>
              <button>Umów Wizytę</button>
            </Link>
          </Fade>
          {/* <div className='content-1'>
            <div className='fitness-links'>
              <div className='card'>
                <div className='image-container'>
                  <img src={rower}></img>
                </div>
                <div className='text'>
                  <h2>Rower Poziomowy</h2>
                  <p>
                    Rower poziomy doskonale sprawdza się w przypadku osób, które narzekają
                    na bóle pleców. Ustawienia ciała w trakcie ćwiczeń pozwala na
                    odciążenie kręgosłupa co jest szczególnie istotne dla ...
                  </p>
                </div>
                <Link to='/strefa-fitness/rower-poziomowy'>
                  <button>Czytaj Dalej</button>
                </Link>
              </div>

              <div className='card'>
                <div className='image-container'>
                  <img src={vacu}></img>
                </div>
                <div className='text'>
                  <h2>Vacu Bieżnia</h2>
                  <p>
                    VACU BIEŻNIA z IR to urządzenie profesjonalnej bieżni zamkniętej w
                    specjalnej kapsule z podciśnieniem i podczerwienią (IR), gdzie zgubisz
                    zbędne kilogramy nawet bez zastosowania diety.
                  </p>
                </div>
                <Link to='/strefa-fitness/vacu-bieznia-ir-professional'>
                  <button>Czytaj Dalej</button>
                </Link>
              </div>
              <div className='card'>
                <div className='image-container'>
                  <img src={orbitrek}></img>
                </div>
                <div className='text'>
                  <h2>Swan Orbitrek</h2>
                  <p>
                    Urządzenie to łączy w sobie cztery komponenty, a połączenie ich w
                    jednym urządzeniu powoduje, że podczas treningu działamy na całe
                    ciało, gdzie jedno działanie potęguje drugie i uzupełnia kolejne.{' '}
                  </p>
                </div>
                <Link to='/strefa-fitness/swan-orbitrek-ir'>
                  <button>Czytaj Dalej</button>
                </Link>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default Rollmasazer
